@tailwind base;
@tailwind components;
@tailwind utilities;

.h3 {
  @apply text-xl font-bold mt-4 mb-2;
}

.show-for-sr {
  @apply sr-only;
}

ul.errorlist {
  @apply text-sm text-red-600 mt-2;
}

ul.pagination {
  display: flex;
  flex-direction: row;
  gap: 10px;

  a {
    @apply text-blue-800;
  }

  .current {
    @apply bg-gray-300 px-1;
  }

  .ellipsis:after {
    @apply px-1;
    content: "…";
  }

  .pagination-previous a:before,
  .pagination-previous.disabled:before {
    @apply mr-1 inline-block;
    content: "«";
  }

  .pagination-next a:after,
  .pagination-next.disabled:after {
    @apply ml-1 inline-block;
    content: "»";
  }
}

.htmx-indicator {
  @apply hidden;

  &.htmx-request {
    @apply inline;
  }
}
